import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div class="container">
      <h1>Thanks for Reaching Out!</h1>
      <p>
        We will review your message shortly and get back to you. If you wish to
        get in touch with us sooner please email{' '}
        <a href="mailto:team@crowdcg.com">team@crowdcg.com</a> or call{' '}
        <a href="tel:732-544-2329">732-544-2329</a>.
      </p>
    </div>
  </Layout>
)

export default IndexPage
